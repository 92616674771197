const {env} = require('./env')

// Base
exports.baseRoute = env.REACT_APP_BASE_ROUTE;

// API
let apiPath = env.REACT_APP_API_URL;

exports.api = {};
exports.api.path = apiPath;
window.apiPath = apiPath; // Add apiPath here instead of in the configurator.js

// Browsers Support
exports.browsersSupported = [
    {name: "chrome", supported: true},
    {name: "firefox", supported: true},
    {name: "ie", supported: false},
    {name: "edge", supported: true},
    {name: "safari", supported: true}
]

exports.maintenanceMode = false;

// Google analytics
exports.ga = {};
exports.ga.trackingId = env.REACT_APP_GA_TRACKING_ID;
exports.ga.debug = false;