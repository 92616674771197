import React, { Component, Fragment } from 'react';
import Unity, { UnityContent } from "react-unity-webgl";
import '../assets/style/configurator.css';
import LoadingScreen from '../components/LoadingScreen';
import Store from 'eas-react-store';
import { Link } from 'react-router-dom';
import {env} from "../env";
import config from '../config';

class ConfiguratorView extends Component {
    constructor (props) {
        super(props);

        this.state = {
            progression: 0,
            isLoading: true,
            hasError: false
        };

        this.unityContent = new UnityContent(
            env.PUBLIC_URL + "/latest/Build/tylohelo_build.json",
            env.PUBLIC_URL + "/latest/Build/UnityLoader.js"
        );

        this.unityContent.on("progress", progression => {
            this.setState({
                progression
            });
        });

        this.unityContent.on("error", error => {
            this.props.store.User.Api.sendError(error.message);

            this.setState({
                hasError: true
            });
        });

        this.unityContent.on("loaded", () => {
            this.setState({
                isLoading: false
            });
        });

        let urlParams = new URLSearchParams((new URL(window.location.href)).search);
        let key = urlParams.get('dealer');

        if (key != null && (key === 'true' || key === '1')) {
            if (! this.props.store.User.data.user.super_admin && ! this.props.store.User.data.user.customer_admin) {
                let param = (typeof this.props.match.params.uid !== 'undefined') ? this.props.match.params.uid : '0';
                window.location.href = `${env.PUBLIC_URL}/?redirect=${param}`;
            }
        }

        this.onLogout = this.onLogout.bind(this);
        this.onBackToAdmin = this.onBackToAdmin.bind(this);

    }

    componentDidMount (){
        window.onpopstate = (e) => {
            e.preventDefault();
            window.location.href = env.PUBLIC_URL + "/admin";
        };
    }

    async onLogout () {
        let response = await this.props.store.User.Api.logout();
        if (response) {
            window.location.href = env.PUBLIC_URL + "/";
        }
    }

    async onBackToAdmin () {
        window.location.href = env.PUBLIC_URL + "/admin";
    }

    render() {
        var isAdmin = (
            typeof(this.props.store.User.data.user) !== 'undefined' &&
            this.props.store.User.data.user != null &&
            (
                this.props.store.User.data.user.super_admin === "1" ||
                this.props.store.User.data.user.customer_admin === "1"
            )
        );

        var isLoggedIn = (
            typeof(this.props.store.User.data.user) !== 'undefined' &&
            this.props.store.User.data.user != null &&
            typeof(this.props.store.User.data.user.username) === 'string' &&
            this.props.store.User.data.user.username.length > 0
        );

        if (! this.state.hasError) {
            return (
                <div className="configurator-container">
                    {this.state.isLoading &&
                        <LoadingScreen progression={`${this.state.progression * 100}%`} />
                    }

                    {(isAdmin) &&
                        <div className="mainBtn">
                            <button type="button" className="noselect" onClick={this.onBackToAdmin}>{React.lang.Configurator.goBackToAdmin}</button>
                        </div>
                    }

                    {(!isAdmin && isLoggedIn) &&
                        <div className="mainBtn">
                            <button type="button" className="noselect" onClick={this.onLogout}>{React.lang.Configurator.logout}</button>
                        </div>
                    }
                    <Unity unityContent={this.unityContent} width="100%" height="100%" />
                    <script>window.apiPath = "{config.api.path}";</script>
                </div>
            );
        } else {
            return (
                <Fragment>
                    <div className="content-wrapper">
                        <p>{React.lang.Configurator.error}</p>
                        <a className="reload" href={`${env.PUBLIC_URL}/configurator`}>Reload the configurator</a>
                    </div>
                </Fragment>
            )
        }
    }
}

export default Store.connectStore(ConfiguratorView);
