import React, { Component } from 'react';
import {env} from "../env";

class MaintenanceView extends Component {
    render() {
        return (
            <div className="content-wrapper">
                <img className="tylohelo-logo" src={`${env.PUBLIC_URL}/img/tylo.jpg`} alt="Tylo logo" />
                <p>{React.lang.MaintenanceView.text}</p>
            </div>
        );
    }
}

export default MaintenanceView;