// Opera 8.0+
let opera = navigator.userAgent.toLowerCase().indexOf("op") > -1;

// Firefox 1.0+
let firefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
let safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// Internet Explorer 6-11
let ie = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
let edge = !ie && !!window.StyleMedia;

// Chrome 1 - 71
// let chrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
let chrome = /chrome/i.test( navigator.userAgent );

let myBrowser = '';

if (chrome) {
    myBrowser = 'chrome';
} else if (edge) {
    myBrowser = 'edge';
} else if (ie) {
    myBrowser = 'ie';
} else if (safari) {
    myBrowser = 'safari';
} else if (firefox) {
    myBrowser = 'firefox';
} else if (opera) {
    myBrowser = 'opera';
}

module.exports = myBrowser;
