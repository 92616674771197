import React, { Component, Fragment } from 'react';
import '../assets/style/progress-bar.css';
import {env} from "../env";

class LoadingScreen extends Component {
    render() {
        return (
            <Fragment>
                <div className="content-wrapper">
                    <img className="tylohelo-logo" src={`${env.PUBLIC_URL}/img/tylo.jpg`} alt="Tylo logo" />
                    <img className="header-logo" src={`${env.PUBLIC_URL}/img/saunaloader.gif`} alt="Heater logo" />
                    <p>Heating up 3D …</p>
                    <div className="progress-container">
                        <div style={{width: this.props.progression}} className="progress-bar"></div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default LoadingScreen;
