import React from 'react';
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Store from 'eas-react-store';
import { Link } from 'react-router-dom';
import {env} from "../env";

class HeaderSection extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            username: ""
        }

        this.onLogout = this.onLogout.bind(this);
    }

    async componentDidMount () {
        this.setState({
            username: this.props.store.User.data.user.username
        });
    }

    handleSelect(eventKey) {
        alert(`selected ${eventKey}`);
    }

    async onLogout () {
        let response = await this.props.store.User.Api.logout();

        if (response) {
            window.location = env.PUBLIC_URL + "/";
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex flex-row justify-content-between py-4">
                        <div className="admin-logo mw-100p">
                            <img src={env.PUBLIC_URL + "/img/tylo.jpg"} className="mw-100" alt=""/>
                        </div>
                        <Nav>
                            <Nav.Item>
                                <Nav.Link eventKey="1" target="_blank">
                                    <Link to="/configurator">
                                        {this.props.text.configurator}
                                    </Link>
                                </Nav.Link>
                            </Nav.Item>
                            <NavDropdown title={this.state.username} id="nav-dropdown">
                                <NavDropdown.Item onClick={this.onLogout} eventKey="4.1">{this.props.text.logout}</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </div>
                </div>
            </div>
        );
    }
}

export default Store.connectStore(HeaderSection);
