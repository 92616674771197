import external from '../tools/base.external';

export default class Api_dealer extends external {
    async update (customer_number, data, token) {
        let user = await this.put.json(`dealers/${customer_number}`, data, token);

        return user;
    }

    async getDealer (customer_number, token) {
        return await this.get.json(`dealers/${customer_number}/full`, token);
    }
}
