import React, { Component, Fragment } from 'react';

class AdminMarkup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInEditMode: false
        };

        this.updateValue = this.updateValue.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.changeEditMode = this.changeEditMode.bind(this);
    }

    componentDidUpdate () {
        if (this.state.isInEditMode) {
            this.refs.theTextInput.focus();
        }
    }

    handleKeyPress (event) {
        if(event.key === 'Enter'){
            this.updateValue();
       }
    }

    changeEditMode () {
        this.setState({
            isInEditMode: ! this.state.isInEditMode
        });

        if (this.state.isInEditMode) {
            this.props.defaultValueUpdate('markup');
        }
    }

    updateValue () {
        this.props.onSubmit();

        this.setState({
            isInEditMode: false
        });
    }

    render() {
        return (
            <div className={`card mt-3 d-flex flex-column flex-sm-row align-items-start align-items-sm-center ${this.state.isInEditMode ? 'edit-mode' : ''}`}>
                {this.state.isInEditMode ?
                    <Fragment>
                        <div className="card-body d-flex flex-column">
                            <div className="form-group my-2 ">
                                <input
                                    type="number"
                                    step="0.01"
                                    onChange={this.props.onChange}
                                    name="markup"
                                    onKeyPress={this.handleKeyPress}
                                    className="form-control admin-big-field"
                                    defaultValue={this.props.value}
                                    ref="theTextInput"
                                />
                            </div>
                            <p className="color-mid-grey m-0">{this.props.text.markup}</p>
                        </div>
                        <button onClick={this.changeEditMode} className="btn btn-close my-3 mr-1">{this.props.text.close}</button>
                        <button onClick={this.updateValue} className="btn btn-primary my-3 mr-3">{this.props.text.save}</button>
                    </Fragment>
                :
                    <Fragment>
                        <div className="card-body d-flex flex-column">
                            <h3  className="admin-big-number mb-0">{this.props.value} {this.props.text.signAfterValue}</h3>
                            <p className="color-mid-grey m-0">{this.props.text.markup}</p>
                        </div>
                        <button onClick={this.changeEditMode} className="btn btn-main m-3 align-self-center">{this.props.text.edit}</button>
                    </Fragment>
                }
            </div>
        );
    }
}

export default AdminMarkup;
