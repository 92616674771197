function setCookie(name, value, days) {
    let expires = "";

    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie = `${name}=${value || ""}${expires}; path=/`;
}

function getCookie(name) {
    let result = '';
    let cookies = document.cookie.split(';');

    cookies.forEach((cookie) => {
        let cookieParts = cookie.split('=');

        if (cookieParts[0].trim() == name) {
            result = cookieParts[1];
        }
    });

    return result;
}

function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; patht=/`;
}

module.exports = {
    getCookie,
    setCookie,
    deleteCookie
}