import React, { Component } from 'react';
import ImageBackground from '../components/ImageBackground.jsx';
import LoginSection from '../components/LoginSection.jsx';

class LoginView extends Component {
    render() {
        return (
            <div className="container-fluid h-100vh">
                <div className="row h-100">
                    <ImageBackground />
                    <LoginSection text={React.lang.LoginSection} />
                </div>
            </div>
        );
    }
}

export default LoginView;