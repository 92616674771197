export const strings = {
    "LoginSection": {
        "header": {
            "left": "Log in",
            "right": "| Sales & Admin"
        },
        "description": "Login to use the 3d configurator tool, works for both admin and salespersons.",
        "form": {
            "email": {
                "label": "Email address",
                "placeholder": "Enter email"
            },
            "password": {
                "label": "Password",
                "placeholder": "Enter password"
            },
            "errors": {
                "field": (key) => `Your ${key} cannot be empty`,
                "login": `The password or/and email is wrong`
            },
            "submit": "Submit"
        }
    },
    "AdminView": {
        "HeaderSection": {
            "configurator": "3D Configurator",
            "logout": "Log Out"
        },
        "ContentSection": {
            "title": "Admin Portal",
            "description": "Set your own markup or change currency. By default prices are shown as gross price."
        },
        "AdminToolbar": {
            "AdminMarkup": {
                "markup": "Price markup for clients",
                "save": "Save",
                "close": "Close",
                "signAfterValue": "%+",
                "edit": "Edit Markup"
            },
            "AdminCurrency": {
                "valueEqualTo": (currency) => `1 ${currency} =`,
                "currency": "Currency conversion to clients",
                "save": "Save",
                "close": "Close",
                "edit": "Edit Currency"
            }
        }
    },
    "Configurator": {
        "goBackToAdmin": "Go back to admin",
        "logout": "Log Out",
        "error": " An error has occurred in the configurator"
    },
    "BrowserNotSupported": {
        "firstText": "Your browser is not supported by the configurator",
        "secondText": `Please download and install <a target="_blank" href="https://www.google.se/intl/se/chrome/">Chrome</a>`
    },
    "MaintenanceView": {
        "text": "Webbsidan är nere för tillfället"
    }
};