import Base_controller from './base.controller';
import { Api_User } from '../api';
import {env} from '../../env';

export default class User extends Base_controller {
    constructor (updateStore, config, localStorage = null) {
        super(updateStore);

        this.Api = {};
        this.Api.login = this.login.bind(this);
        this.Api.isConnected = this.isConnected.bind(this);
        this.Api.sendError = this.sendError.bind(this);
        this.Api.logout = this.logout.bind(this);
        this.Api.user = new Api_User(env.REACT_APP_API_URL);

        if (localStorage != null && localStorage.User != null) {
            this.data = localStorage.User;
            if (this.data != null) {
                this.data.api_path = env.REACT_APP_API_URL;
            }
        } else{
            this.data = {
                token: "",
                api_path: env.REACT_APP_API_URL,
                user: {}
            };
        }

        this.updateStore();
    }

    async login (data) {
        let user = await this.Api.user.login(data);
        if (typeof user.token !== "undefined") {
            this.data.token = user.token;
            this.data.user = JSON.parse(atob(user.token.split('.')[1]))
            this.updateStore();

            return true;
        }

        return false;
    }

    async isConnected () {
        if(this.data.token === ""){
            return false;
        }

        let response = await this.Api.user.verifyLogin(this.data.token);

        if (response.success === 1) {
            return true;
        }

        if (response.errorCode == "us_ve_Error") {
            this.data.user = {};
            this.updateStore();
        }

        return false;
    }

    async sendError (message) {
        let response = await this.Api.user.sendError({errorMessage: message});

        return response;
    }

    async logout () {
        this.data.token = "";
        this.data.user = {};
        this.updateStore();

        return true;
    }
}