import React, { Component } from 'react';
import FormSection from './FormSection.jsx';
import {env} from "../env";


class LoginSection extends Component {
    render() {
        return (
            <div className="col-xs-12 col-sm-5 col-md-4 col-xl-3 sidebar">
                <div className="d-flex h-100 align-items-center">
                    <div className="row px-2">
                        <div className="col-12 pb-4">
                            <img src={env.PUBLIC_URL + "/img/tylo.jpg"} className="w-50" alt=""/>
                        </div>
                        <div className="col-12 pb-4">
                            <h1 className="login-heading">{this.props.text.header.left} <span className="font-weight-light color-mid-grey">{this.props.text.header.right}</span></h1>
                        </div>
                        <div className="col-12 pb-4">
                            <p className="color-dark-grey">{this.props.text.description}</p>
                        </div>
                        <div className="col-12 pb-4">
                            <FormSection text={this.props.text.form} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginSection;
