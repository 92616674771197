import React from 'react';
import {env} from "../env";

class ImageBackground extends React.Component {
    render() {
        return (
            <div className="d-none d-sm-block col-sm-7 col-md-8 col-xl-9">
                <div className="row">
                    <img src={env.PUBLIC_URL + "/img/sauna.jpg"} className="img-full" alt=""/>
                </div>
            </div>
        );
    }
}

export default ImageBackground;
