import React from 'react';


class ContentSection extends React.Component {
    render() {
        return (
            <div className="container py-5">
                <div className="row">
                    <div className="col-xs-12 px-3">
                        <h1 className="pb-3">{this.props.text.title}</h1>
                        <p className="color-dark-grey">{this.props.text.description}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentSection;
