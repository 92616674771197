import React, { Component, Fragment } from 'react';

class AdminCurrency extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isInEditMode: false
        };

        this.updateValue = this.updateValue.bind(this);
        this.changeEditMode = this.changeEditMode.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidUpdate(){
        if (this.state.isInEditMode && this.refs.theCurrency !== document.activeElement) {
            this.refs.theValue.focus();
        }
    }

    handleKeyPress (event) {
        if(event.key === 'Enter'){
            this.updateValue();
        }
    }

    changeEditMode () {
        this.setState({
            isInEditMode: !this.state.isInEditMode
        })

        if (this.state.isInEditMode) {
            this.props.defaultValueUpdate('exchange_rate');
            this.props.defaultValueUpdate('currency_symbol');
        }
    }

    updateValue () {
        this.props.onSubmit();

        this.setState({
            isInEditMode: false
        });
    }

    render() {
        return (
            <div className={`card mt-3 d-flex flex-column flex-sm-row align-items-start align-items-sm-center ${this.state.isInEditMode ? 'edit-mode' : ''}`}>
                {this.state.isInEditMode ?
                    <Fragment>
                        <div className="card-body d-flex flex-column">
                            <p className="font-weight-bold mb-0">{this.props.text.valueEqualTo(this.props.trading_currency)}</p>
                            <div className="form-group my-2 ">
                                <input
                                    type="number"
                                    step="0.01"
                                    name="exchange_rate"
                                    onChange={this.props.onChange}
                                    onKeyPress={this.handleKeyPress}
                                    className="form-control admin-big-field mr-2"
                                    value={this.props.value}
                                    ref="theValue"
                                />

                                <input
                                    type="text"
                                    name="currency_symbol"
                                    onChange={this.props.onChange}
                                    onKeyPress={this.handleKeyPress}
                                    className="form-control admin-big-field"
                                    value={this.props.currency}
                                    ref="theCurrency"
                                />
                            </div>
                            <p className="color-mid-grey m-0">{this.props.text.currency}</p>
                        </div>
                        <button onClick={this.changeEditMode} className="btn btn-close my-3 mr-1 ">{this.props.text.close}</button>
                        <button onClick={this.updateValue} className="btn btn-primary my-3 mr-3">{this.props.text.save}</button>
                    </Fragment>
                :
                    <Fragment>
                        <div className="card-body d-flex flex-column">
                            <p className="font-weight-bold mb-0">{this.props.text.valueEqualTo(this.props.trading_currency)}</p>
                            <h3  className="admin-big-number mb-0">
                                {this.props.value}
                                <span className="currency-name pl-2">
                                    {this.props.currency}
                                </span>
                            </h3>
                            <p className="color-mid-grey m-0">{this.props.text.currency}</p>
                        </div>
                        <button onClick={this.changeEditMode} className="btn btn-main m-3 align-self-center">{this.props.text.edit}</button>
                    </Fragment>
                }
            </div>
        );
    }
}

export default AdminCurrency;
