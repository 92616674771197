import React, { Component, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/style/App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import LoginView from './views/LoginView.jsx';
import AdminView from './views/AdminView.jsx';
import SafeRoute from './components/SafeRoute.jsx';
import Store from 'eas-react-store';
import ConfiguratorView from './views/ConfiguratorView';
import Routes from './routes';
import config from './config';
import myBrowser from './browsers.js';
import BrowserNotSupported from './views/BrowserNotSupported';
import Cookies from './components/Cookies';
import withTracker from './components/WithTracker'
import {env} from "./env";

class App extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isConnected: false,
      finishLoading: false
    };
  }

  async componentDidMount () {
      this.setState({
        isConnected: await this.props.store.User.Api.isConnected(),
        finishLoading: true
      });
  }

  getSupportedBrowsers () {
    let supportedBrowsers = [];

    config.browsersSupported.forEach((item) => {
      if (item.supported === true) {
        supportedBrowsers[item.name] = true;
      }
    });

    if (supportedBrowsers[myBrowser] != null) {
      return true;
    }

    return false;
  }

  render() {
    if (this.state.finishLoading) {
      return (
        <Router basename={env.REACT_APP_BASE_ROUTE}>
          {this.getSupportedBrowsers() === true ?
            <Route exact path={Routes.configurator.path} component={withTracker(ConfiguratorView)} />
          :
            <Route exact path={Routes.configurator.path} component={withTracker(BrowserNotSupported)} />
          }

          <Fragment>
            <SafeRoute exact path={Routes.login.path} component={LoginView}
              redirections={
                {
                  [Routes.admin.path]: this.state.isConnected && (this.props.store.User.data.user.super_admin === "1" || this.props.store.User.data.user.customer_admin === "1"),
                  [Routes.configurator.path]: this.state.isConnected && (this.props.store.User.data.user.super_admin === "0" && this.props.store.User.data.user.customer_admin === "0")
                }
              }
              finishLoading={this.state.finishLoading}
            />
            <SafeRoute exact path={Routes.admin.path} component={AdminView}
              redirections={
                {
                  [Routes.login.path]: ! this.state.isConnected,
                  [Routes.configurator.path]: this.state.isConnected && this.props.store.User.data.user.super_admin === "0" && this.props.store.User.data.user.customer_admin === "0"
                }
              }
              finishLoading={this.state.finishLoading}
            />
          </Fragment>

          <Cookies />
        </Router>
      );
    } else {
      return (<p></p>);
    }
  }
}



export default Store.connectStore(App);
