import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import withTracker from './WithTracker';

class SafeRoute extends Component {
    render() {
        Object.keys(this.props.redirections).forEach((key) => {
            if (this.props.redirections[key]) {
                this.key = key;
            }
        });

        const Component = this.props.component;

        if (this.props.finishLoading) {
            if (this.key != null) {
                return (
                    <Route exact={this.props.exact} path={this.props.path} component={() => <Redirect to={this.key} />} />
                );
            } else {
                return (
                    <Route exact={this.props.exact} path={this.props.path} component={withTracker(Component)} />
                );
            }
        } else {
            return (
                <p></p>
            );
        }
    }
}

export default SafeRoute;