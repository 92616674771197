import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AdminMarkup from './AdminMarkup.jsx';
import AdminCurrency from './AdminCurrency.jsx';
import Store from 'eas-react-store';

class AdminToolbar extends React.Component {
    _isMounted = false;

    constructor(props, context) {
        super(props, context);
        this.state = {
            key: 'markup',
            markup: "",
            exchange_rate: "",
            currency_symbol: "",
            defaultData: {
                markup: "",
                exchange_rate: "",
                currency_symbol: ""
            }
        };

        this.onChange = this.onChange.bind(this);
        this.saveDealer = this.saveDealer.bind(this);
        this.updateToDefaultState = this.updateToDefaultState.bind(this);
    }

    async componentDidMount () {
        this._isMounted = true;

        let data = await this.props.store.Dealer.Api.getDealer();

        if (this._isMounted) {

            if (data.dealer.data.Markup == null) data.dealer.data.Markup = '';
            if (data.dealer.data.ExchangeRate == null) data.dealer.data.ExchangeRate = '';
            if (data.dealer.data.CurrencyCode == null) data.dealer.data.CurrencyCode = '';
            if (data.dealer.data.TradingCurrency == null) data.dealer.data.TradingCurrency = '';

            this.setState({
                markup: data.dealer.data.Markup,
                exchange_rate: data.dealer.data.ExchangeRate,
                currency_symbol: data.dealer.data.CurrencyCode,
                trading_currency: data.dealer.data.TradingCurrency,
                defaultData: {
                    markup: data.dealer.data.Markup,
                    exchange_rate: data.dealer.data.ExchangeRate,
                    currency_symbol: data.dealer.data.CurrencyCode,
                }
            });
        }
    }

    async componentWillUnmount () {
        this._isMounted = false;
    }

    async saveDealer () {

        var markup = this.state.markup;
        var exchange_rate = this.state.exchange_rate;
        var currency_symbol = this.state.currency_symbol;

        if (markup === '') markup = '0';
        if (exchange_rate === '') exchange_rate = '0';
        if (currency_symbol === '') currency_symbol = '';

        if (
            ! isNaN(Number(markup)) &&
            ! isNaN(Number(exchange_rate)) &&
            currency_symbol.length <= 3 &&
            markup !== '' &&
            exchange_rate !== ''
        ) {
            await this.props.store.Dealer.Api.update({
                markup: markup,
                exchange_rate: exchange_rate,
                currency_symbol: currency_symbol
            });

            this.setState({
                defaultData: {
                    markup: markup,
                    exchange_rate: exchange_rate,
                    currency_symbol: currency_symbol,
                }
            });
        }  else {
            this.setState({
                markup: this.state.defaultData.markup,
                exchange_rate: this.state.defaultData.exchange_rate,
                currency_symbol: this.state.defaultData.currency_symbol,
            });
        }
    }

    onChange (event) {
        this.setState({
            [event.target.name]: (event.target.name === 'currency_symbol') ? event.target.value.toUpperCase() : event.target.value
        });
    }

    updateToDefaultState (field) {
        this.setState({
            [field]: this.state.defaultData[field]
        });
    }

    render() {
        return (
            <div className="container py-3">
                <Tabs id="admin-tab" activeKey={this.state.key} onSelect={key => this.setState({key})} >
                    <Tab eventKey="markup" title="Markup">
                        <AdminMarkup
                            onSubmit={this.saveDealer}
                            value={this.state.markup}
                            onChange={this.onChange}
                            text={this.props.text.AdminMarkup}
                            defaultValue={this.state.defaultData.markup}
                            defaultValueUpdate={this.updateToDefaultState}
                        />
                    </Tab>
                    <Tab eventKey="currency" title="Currency conversion">
                        <AdminCurrency
                            onSubmit={this.saveDealer}
                            value={this.state.exchange_rate}
                            currency={this.state.currency_symbol}
                            trading_currency={this.state.trading_currency}
                            defaultValue={this.state.defaultData.exchange_rate}
                            defaultCurrency={this.state.defaultData.currency_symbol}
                            defaultValueUpdate={this.updateToDefaultState}
                            onChange={this.onChange}
                            text={this.props.text.AdminCurrency}
                        />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default Store.connectStore(AdminToolbar);
