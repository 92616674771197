import React, { Component, Fragment } from 'react';
import HeaderSection from '../components/HeaderSection';
import ContentSection from '../components/ContentSection';
import AdminToolbar from '../components/AdminToolbar';


class AdminView extends Component {
    render() {
        return (
            <Fragment>
                <HeaderSection text={React.lang.AdminView.HeaderSection} />
                <ContentSection text={React.lang.AdminView.ContentSection} />
                <AdminToolbar text={React.lang.AdminView.AdminToolbar} />
            </Fragment>
        );
    }
}

export default AdminView;
