import React, { Component } from 'react';
import {env} from '../env'

class BrowserNotSupported extends Component {
    render() {
        return (
            <div className="content-wrapper">
                <img className="tylohelo-logo" src={`${env.PUBLIC_URL}/img/tylo.jpg`} alt="Tylo logo" />
                <p>{React.lang.BrowserNotSupported.firstText}</p>
                <p dangerouslySetInnerHTML={{ __html: React.lang.BrowserNotSupported.secondText }} />
            </div>
        );
    }
}

export default BrowserNotSupported;