import React, { Component, Fragment } from 'react';
import '../assets/style/cookies.css';
import { getCookie, setCookie } from '../tools';

class Cookies extends Component {
    constructor(props) {
        super(props);

        let cookie = getCookie('accept_cookie');

        this.state = {
            showBanner: cookie != '' ? ! cookie : true
        };

        this.accept = this.accept.bind(this);
    }

    accept(e) {
        this.setState({
            showBanner: false
        });

        setCookie('accept_cookie', true, 365);
    }

    render() {
        return (
            <Fragment>
                {this.state.showBanner &&
                    <div className="cookie-banner">
                        <div className="container">
                            <p>
                                This website stores <a target="_blank" href="https://www.tylohelo.com/policy">cookies</a> on your computer.
                                These cookies are used to improve your website and provide more personalized
                                services to you, both on this website and through other media.

                                <button className="btn btn-main" onClick={this.accept}>Accept</button>
                            </p>
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

export default Cookies;
