import React, { Component, Fragment } from 'react';
import Store from 'eas-react-store';
import Routes from '../routes';
import ErrorInput from './ErrorInput';
import {env} from "../env";

class FormSection extends Component {
    constructor (props) {
        super(props);

        this.state = {
            fields: {
                email: '',
                password: '',
            },
            errors: {
                email: '',
                password: '',
                login: ''
            }
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange (event) {
        this.setState({
            fields: {
                ...this.state.fields,
                [event.target.name]: event.target.value
            }
        });

        if (this.state[event.target.name] !== '') {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [event.target.name]: '',
                    "login": ''
                }
            });
        }
    }

    validateForm () {
        let shouldReturn = true;

        Object.keys(this.state.fields).forEach((key) => {
            let field = this.state.fields[key];

            if (field === '') {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        [key]: this.props.text.errors.field(key)
                    }
                }));

                shouldReturn = false;
            }
        });

        return shouldReturn;
    }

    async onSubmit (e) {
        e.preventDefault();

        if (this.validateForm()) {
            let response = await this.props.store.User.Api.login({username: this.state.fields.email, password: this.state.fields.password});
            let urlParams = new URLSearchParams((new URL(window.location.href)).search);
            let key = urlParams.get('redirect');

            if (key != null) {
                let param = (key !== '0') ? `/${key}` : '';

                window.location = `${env.PUBLIC_URL}${Routes.configurator.path}${param}`;
            } else {
                if (response && (this.props.store.User.data.user.customer_admin === "1" || this.props.store.User.data.user.super_admin === "1")) {
                    window.location = `${env.PUBLIC_URL}${Routes.admin.path}`;
                } else if (response && this.props.store.User.data.user.customer_admin === "0" && this.props.store.User.data.user.super_admin === "0") {
                    window.location = `${env.PUBLIC_URL}${Routes.configurator.path}`;
                } else {
                    this.setState(prevState => ({
                        errors: {
                            ...prevState.errors,
                            "login": this.props.text.errors.login
                        }
                    }));
                }
            }
        }
    }

    render () {
        return (
            <Fragment>
                {this.state.errors.login !== '' &&
                    <ErrorInput text={this.state.errors.login} />
                }
                <div className="d-block">
                    <form>
                        <div className="form-group">
                            <label className="label-small" htmlFor="email">{this.props.text.email.label}</label>
                            <input
                                onChange={this.onChange}
                                type="email"
                                name="email"
                                className="form-control"
                                aria-describedby="emailHelp"
                                placeholder={this.props.text.email.placeholder}
                                id="email"
                            />
                            {this.state.errors.email !== '' &&
                                <ErrorInput text={this.state.errors.email} />
                            }
                        </div>
                        <div className="form-group pb-3">
                            <label className="label-small" htmlFor="password">{this.props.text.password.label}</label>
                            <input
                                onChange={this.onChange}
                                name="password"
                                type="password"
                                className="form-control"
                                placeholder={this.props.text.password.placeholder}
                                id="password"
                            />
                            {this.state.errors.password !== '' &&
                                <ErrorInput text={this.state.errors.password} />
                            }
                        </div>
                        <button
                            type="submit"
                            className="btn btn-main w-100"
                            onClick={this.onSubmit}
                        >
                                {this.props.text.submit}
                        </button>
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default Store.connectStore(FormSection);
