import React from 'react';
import ReactDOM from 'react-dom';
import './assets/style/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';
import { User, Dealer } from './app/controllers';
import Store from 'eas-react-store';
import { strings as swedish } from './assets/languages/swe';
import MaintenanceView from './views/MaintenanceView';
import ReactGA from 'react-ga';
import {env} from "./env";

ReactGA.initialize(
    config.ga.trackingId,
    {debug: config.ga.debug}
);

React.lang = swedish;


if (config.maintenanceMode) {
    ReactDOM.render(<MaintenanceView />, document.getElementById('root'));
} else {
    Store.createStore([User, Dealer], env.REACT_APP_API_URL);

    ReactDOM.render(<App />, document.getElementById('root'));
}

serviceWorker.unregister();
