import Base_controller from './base.controller';
import { Api_Dealer } from '../api';
import {env} from "../../env";

export default class Dealer extends Base_controller {
    constructor (updateStore, config, localStorage = null) {
        super(updateStore);

        this.Api = {};
        this.Api.update = this.update.bind(this);
        this.Api.getDealer = this.getDealer.bind(this);
        this.Api.dealer = new Api_Dealer(env.REACT_APP_API_URL);

        if (localStorage != null) {
            this.token = localStorage.User.token;
            this.user = localStorage.User.user;
        }

        this.updateStore();
    }

    async getDealer () {
        return await this.Api.dealer.getDealer(this.user.customer_number, this.token);
    }

    async update (data) {
        let response = await this.Api.dealer.update(this.user.customer_number, data, this.token);

        if (response.success === 1) {
            return true;
        }

        return false;
    }
}