import external from '../tools/base.external';

export default class Api_user extends external {
    async login (data) {
        let user = await this.post.json('users/login', data);

        return user;
    }

    async verifyLogin (token) {
        let response = await this.get.json('users/verify', token);

        return response;
    }

    async sendError (data) {
        let response = await this.post.json('testing/error', data);

        return response;
    }
}
